import React from "react";
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};

const paragraphStyles = {
  marginBottom: 48,
  fontSize: "1.4rem"
};

export {pageStyles, paragraphStyles}