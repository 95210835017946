import * as React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import PageHeading from "../components/page_heading";
import { StaticImage } from "gatsby-plugin-image";
import { VerticalCenterer } from "../components/utils";
import { pageStyles, paragraphStyles } from "../components/pages/404";

// styles
// markup
const NotFoundPage = () => {
  const headingText = "404: rotten cheese";
  return (
    <Layout iconName="404">
      <PageHeading headingText={headingText} />
      <main style={pageStyles}>
        <VerticalCenterer>
          <StaticImage
            src="../assets/404.jpg"
            alt="404 image, little mouse"
            placeholder="blurred"
            layout="fixed"
            width={500}
          />
          <title>Not found</title>
          <p style={paragraphStyles}>
            Sorry we couldn’t find what you were looking for... :(
            <br />
            <Link to="/">Go home</Link>.
          </p>
        </VerticalCenterer>
      </main>
    </Layout>
  );
};

export default NotFoundPage;
